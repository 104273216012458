import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import AllLeads from "src/views/leads/allLeads";
import AllContactUs from "src/views/contactus/allContactus";
import AllQuotes from "src/views/quotes/allQuotes";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Register = Loadable(
  lazy(() => import("../views/authentication/Register"))
);
const Login = Loadable(lazy(() => import("../views/authentication/Login")));

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/manage-leads", element: <AllLeads /> },
      { path: "/manage-contactus", exact:true,  element: <AllContactUs /> },
      { path: "/manage-quotes", exact: true, element: <AllQuotes /> },
      { path: "dashboard", element: <Navigate to="/manage-leads" /> },
      { path: "*", element: <Navigate to="/manage-leads" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "/auth/register", element: <Register /> },
      { path: "/auth/login", element: <Login /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
