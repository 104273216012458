import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;
// const baseUrl = "https://menttech-website.onrender.com";

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

export const BASE_CALL = {
  post: async (url, payload) => await axios.post(baseUrl + url, payload,getAuthHeaders()),
  put: async (url, payload) =>
    await axios.put(baseUrl + url + `/${payload.id}`, payload,getAuthHeaders()),
  get: async (url, params) =>
    await axios.get(baseUrl + url, {
      ...getAuthHeaders(),
      params
    }),
  delete: async (url) => await axios.delete(baseUrl + url)
};
