import { BASE_CALL } from "./baseCall";

export const API_CALL = {
  sunscription: {
    get: async (payload) => await BASE_CALL.get("/subscriber/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/subscriber/delete/" + payload.id),
    update: async (payload) =>
      await BASE_CALL.put("/subscriber/update", payload)
  },
  users: {
    create: async (payload) => BASE_CALL.post("/user/create", payload),
    getByWallet: async (walletAddress) =>
      await BASE_CALL.get("/user/get-by-wallet", { walletAddress }),
    get: async (payload) => await BASE_CALL.get("/user/get", payload),
    login: async (payload) => await BASE_CALL.post('/user/login',payload),
    verify:async (payload) => await BASE_CALL.post('/user/validate-token')
  },
  lead: {
    get: async (payload) => await BASE_CALL.get("/subscriber/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/subscriber/delete/" + payload.id),

  },
  ContactUs: {
    get: async (payload) => await BASE_CALL.get("/contactus/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/contactus/delete/" + payload.id),
  },
  Quote: {
    get: async (payload) => await BASE_CALL.get("/quotes/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/quotes/delete/" + payload.id),
  },
 
};
