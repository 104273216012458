// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --themecol: gray;
  --themecol2: #1ea1f2;

  --coltheme: #1ea1f2;
}
.bgtheme {
  background-color: var(--themecol) !important;
}
.bgtheme2 {
  background-color: var(--themecol2) !important;
}
.coltheme {
  color: var(--themecol2) !important;
}
.coltheme2 {
  color: var(--themecol2) !important;
}
.cus-btn {
  background-color: var(--themecol2) !important;
  color: white !important;
}

.metamask {
  background-color: #1ea1f2 !important;
  color: white !important;
  font-weight: bold !important;
}

.bold {
  font-weight: bold !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs20 {
  font-size: 20px !important;
}
.fs25 {
  font-size: 25px !important;
}

.searchInput {
  width: 100%;
  border: 1px solid gray;
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  outline: none;
}

.tablebox {
  font-size: 14px !important;
}
.css-66gjpw-MuiResponsiveChart-container {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;;EAEpB,mBAAmB;AACrB;AACA;EACE,4CAA4C;AAC9C;AACA;EACE,6CAA6C;AAC/C;AACA;EACE,kCAAkC;AACpC;AACA;EACE,kCAAkC;AACpC;AACA;EACE,6CAA6C;EAC7C,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,sBAAsB;AACxB","sourcesContent":[":root {\n  --themecol: gray;\n  --themecol2: #1ea1f2;\n\n  --coltheme: #1ea1f2;\n}\n.bgtheme {\n  background-color: var(--themecol) !important;\n}\n.bgtheme2 {\n  background-color: var(--themecol2) !important;\n}\n.coltheme {\n  color: var(--themecol2) !important;\n}\n.coltheme2 {\n  color: var(--themecol2) !important;\n}\n.cus-btn {\n  background-color: var(--themecol2) !important;\n  color: white !important;\n}\n\n.metamask {\n  background-color: #1ea1f2 !important;\n  color: white !important;\n  font-weight: bold !important;\n}\n\n.bold {\n  font-weight: bold !important;\n}\n\n.fs16 {\n  font-size: 16px !important;\n}\n\n.fs20 {\n  font-size: 20px !important;\n}\n.fs25 {\n  font-size: 25px !important;\n}\n\n.searchInput {\n  width: 100%;\n  border: 1px solid gray;\n  font-size: 18px;\n  padding: 10px;\n  border-radius: 10px;\n  outline: none;\n}\n\n.tablebox {\n  font-size: 14px !important;\n}\n.css-66gjpw-MuiResponsiveChart-container {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
